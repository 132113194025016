import api from "../services/api";
import Constants from "../services/constant";

export const uploadFiles = async (files) => {
  const formData = new FormData();
  for (let i = 0; i < files.length; i++) {
    formData.append("files", files[i]);
  }
  const res = await api("post", Constants.END_POINT.UPLOAD_FILES, formData);
  if (res.success) {
    if (res.data) {
      return res.data;
    }
  }
};
export const uploadFiles1 = async (files, key) => {
  const formData = new FormData();
  const alreadyUploaded = files.filter((item) => item.path);
  const localFiles = files.filter((item) => !item.path);

  for (let i = 0; i < localFiles.length; i++) {
    formData.append("files", localFiles[i]);
  }
  const res = await api("post", Constants.END_POINT.UPLOAD_FILES, formData);
  if (res.success) {
    if (res.data) {
      return [...alreadyUploaded, ...res.data];
    }
  }
};

export const uploadFiles2 = async (files) => {
  const promises = files.map(async (item) => {
    if (item.path) {
      return item;
    } else {
      const formData = new FormData();
      formData.append("files", item);
      const res = await api("post", Constants.END_POINT.UPLOAD_FILES, formData);
      if (res.success && res.data) {
        return res.data[0];
      }
    }
  });

  const filesArray = await Promise.all(promises);
  return filesArray;
};

export const uploadImages = async (images) => {
  const promises = images.map(async (item) => {
    if (typeof item === "string") {
      return item;
    } else {
      const formData = new FormData();
      formData.append("file", item);
      const res = await api("post", Constants.END_POINT.UPLOAD_FILE, formData);
      if (res.success && res.data) {
        return res.data.path;
      }
    }
  });

  const urls = await Promise.all(promises);
  return urls;
};
