import React, { useEffect } from "react";
import {
  CustomCheckBox,
  CustomForm,
  CustomInput,
  CustomInputColor,
} from "../../../shared/Input/AllInputs";
import PrimaryButton from "../../../shared/Button/PrimaryButton";
import MenuContainer from "./MenuContainer";
import CustomImageInput from "../../../shared/Input/CustomImageInput";

export default function MenuForm({ type }) {
  const { data, handleChange, loading, onSubmit, setFormType } =
    MenuContainer();
  useEffect(() => {
    setFormType(type);
  }, [type, setFormType]);

  return (
    <>
      <CustomForm style={type === "VIEW" ? { pointerEvents: "none" } : {}}>
        <CustomInput
          label="Menu Name"
          name="name"
          required
          data={data}
          onChange={handleChange}
        />
        <CustomInputColor
          label="Menu Color"
          name="color"
          data={data}
          onChange={handleChange}
        />
        <CustomImageInput
          name="image"
          onFilesChange={handleChange}
          data={data}
          editable={type !== "VIEW" ? true : false}
          col={6}
        />

        <CustomCheckBox
          data={data}
          name="applicablePreparationTime"
          label="Applicable Preparation Time"
          onChange={handleChange}
          checked={data?.applicablePreparationTime}
        />
        {type !== "VIEW" && (
          <PrimaryButton
            label="Save Menu"
            loading={loading}
            onClick={onSubmit}
            col={6}
          />
        )}
      </CustomForm>
    </>
  );
}
