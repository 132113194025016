import React from "react";
import { OrderList } from "primereact/orderlist";
import CustomCard from "../../shared/Card/CustomCard";
import Index from "./index";
import PrimaryButton from "../../shared/Button/PrimaryButton";

export default function ReorderProduct({ name }) {
  const { handleChnage, reorderd, onSave, loading } = Index();
  const itemTemplate = (item) => {
    return (
      <div className="flex flex-wrap p-2 align-items-center gap-3">
        <div className="flex-1 flex flex-column gap-2 xl:mr-8">
          <span className="font-bold">{item.name}</span>
        </div>
      </div>
    );
  };
  return (
    <CustomCard title={name} backable>
      <div className="p-4">
        <OrderList
          value={reorderd}
          onChange={handleChnage}
          itemTemplate={itemTemplate}
          header="Products List"
          dragdrop
        ></OrderList>
        <br />

        <PrimaryButton loading={loading} label="Save" onClick={onSave} />
      </div>
    </CustomCard>
  );
}
