import React from "react";
import Index from "./index";
import IngredientsTable from "./IngredientsTable";

const Ingredients = () => {
  const { allIngredients, loading, history, search, setSearch, onDelete } =
    Index();
  return (
    <IngredientsTable
      allIngredients={allIngredients}
      loading={loading}
      history={history}
      search={search}
      setSearch={setSearch}
      onDelete={onDelete}
    />
  );
};

const comparisonFn = function (prevProps, nextProps) {
  return (
    prevProps.location.pathname === nextProps.location.pathname &&
    prevProps.colorMode === nextProps.colorMode
  );
};
export default React.memo(Ingredients, comparisonFn);
