import React from "react";
import LocationTable from "./LocationTable";
import Index from "./index";

const Locations = () => {
  const { allLocations, loading, history, search, setSearch, setLoading } =
    Index();
  return (
    <LocationTable
      allLocations={allLocations}
      loading={loading}
      history={history}
      search={search}
      setSearch={setSearch}
      setLoading={setLoading}
    />
  );
};

const comparisonFn = function (prevProps, nextProps) {
  return (
    prevProps.location.pathname === nextProps.location.pathname &&
    prevProps.colorMode === nextProps.colorMode
  );
};
export default React.memo(Locations, comparisonFn);
