import React from "react";
import { Checkbox } from "primereact/checkbox";
import { Calendar } from "primereact/calendar";

const DayAndTimeComponent = ({
  days,
  handleDay,
  weekDay,
  handleChangeOpeningTime,
}) => {
  return (
    <div className="grid">
      <p className="md:col-6 m-0">Select Open Days</p>
      <div className="col-12 md:col-6 p-fluid grid">
        <p className="md:col-3 m-0">Open Time:</p>
        <p className="md:col-3 m-0">Rush Start:</p>
        <p className="md:col-3 m-0">Rush End:</p>
        <p className="md:col-3 m-0">Close Time:</p>
      </div>
      {days.map((item, index) => {
        return (
          <>
            <div
              key={index}
              className="col-12 md:col-6 mb-2 flex align-items-center"
            >
              <Checkbox
                id={item.weekDay}
                value={item.isClosed}
                checked={!item.isClosed}
                onChange={(e) => handleDay(e, index)}
              />
              <label htmlFor={item.weekDay} className="ml-2">
                {weekDay[item.weekDay]}
              </label>
            </div>
            <div className="col-12 md:col-6 mb-2 p-fluid grid">
              <div className="col-3 md:col-3">
                <Calendar
                  id={item.weekDay}
                  name="openTime"
                  value={item.openTime}
                  onChange={(e) => handleChangeOpeningTime(e, index)}
                  timeOnly
                  hourFormat="12"
                  disabled={item.isClosed}
                  maxDate={item.rushHourStartTime}
                />
              </div>
              <div className="col-3 md:col-3">
                <Calendar
                  id={item.weekDay}
                  name="rushHourStartTime"
                  value={item.rushHourStartTime}
                  onChange={(e) => handleChangeOpeningTime(e, index)}
                  timeOnly
                  hourFormat="12"
                  disabled={item.isClosed}
                  minDate={item.openTime}
                  maxDate={item.rushHourEndTime}
                />
              </div>
              <div className="col-3 md:col-3">
                <Calendar
                  id={item.weekDay}
                  name="rushHourEndTime"
                  value={item.rushHourEndTime}
                  onChange={(e) => handleChangeOpeningTime(e, index)}
                  timeOnly
                  hourFormat="12"
                  disabled={item.isClosed}
                  minDate={item.rushHourStartTime}
                  maxDate={item.closeTime}
                />
              </div>
              <div className="col-3 md:col-3">
                <Calendar
                  id={item.weekDay}
                  name="closeTime"
                  value={item.closeTime}
                  onChange={(e) => handleChangeOpeningTime(e, index)}
                  timeOnly
                  hourFormat="12"
                  disabled={item.isClosed}
                  minDate={item.rushHourEndTime}
                />
              </div>
            </div>
          </>
        );
      })}
    </div>
  );
};

export default DayAndTimeComponent;
