import React from "react";

export default function InputLayout({ label, name, required, col, extraClassName, errorMessage, data, children }) {
    return (
        <>
            <div className={`px-1 mb-3 col-${col} ${extraClassName}`}>
                <div className="flex flex-column gap-2">
                    <label htmlFor={name}>
                        {label}
                        {required ? <span className="p-error">*</span> : ""}
                    </label>
                    {children}
                    {errorMessage || data?.formErrors?.[name] ? (
                        <small className="p-error">{errorMessage || data?.formErrors?.[name]}</small>
                    ) : null}
                </div>
            </div>
        </>
    );
}
