import { types } from "../types/types";
const intitalState = {
  details: { allOrders: [] },
};

const OrdersMenuReducer = (state = intitalState, action) => {
  switch (action.type) {
    case types.CHANGE_ORDERS_MENU:
      return {
        ...state,
        details: action.payload,
      };
    default:
      return { ...state };
  }
};

export default OrdersMenuReducer;
