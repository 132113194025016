import { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import { getEmployees } from "../../redux/actions/employeeAction";

export default function Index() {
  const history = useHistory();
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(getEmployees(setLoading));
  }, [dispatch]);

  let { allEmployees } = useSelector((state) => state.employee);

  const [loading, setLoading] = useState(false);

  const [search, setSearch] = useState("");
  const getFilteredData = (data, search) => {
    if (search.length) {
      search = search.toLowerCase();
      data = data.filter(
        (item) =>
          item?.name?.toLowerCase().includes(search) ||
          item?.email?.toLowerCase().includes(search) ||
          item?.mobile?.includes(search)
      );
    }
    return data;
  };

  allEmployees = getFilteredData(allEmployees, search);
  return { allEmployees, loading, history, search, setSearch };
}
