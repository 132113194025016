import React from "react";
import Index from "./index";
import AddOnTable from "./AddOnTable";

const AddOn = () => {
  const { allAddOns, loading, history, search, setSearch, onDelete } = Index();
  return (
    <AddOnTable
      allAddOns={allAddOns}
      loading={loading}
      history={history}
      search={search}
      setSearch={setSearch}
      onDelete={onDelete}
    />
  );
};

const comparisonFn = function (prevProps, nextProps) {
  return (
    prevProps.location.pathname === nextProps.location.pathname &&
    prevProps.colorMode === nextProps.colorMode
  );
};
export default React.memo(AddOn, comparisonFn);
