import React from "react";
import { useHistory } from "react-router-dom";
import { InputText } from "primereact/inputtext";
import PrimaryButton from "../Button/PrimaryButton";

export default function TableCard({
  title,
  onSearch,
  searchKeyword,
  buttonTitle,
  onClick,
  linkTo,
  children,
  extraButtons,
}) {
  const history = useHistory();
  return (
    <div className="label-table">
      {title || onSearch || linkTo || buttonTitle ? (
        <div className="surface-800 py-2 px-3 flex justify-content-between w-full m-0 flex-wrap">
          <div className="text-2xl text-white w-12 md:w-6">{title}</div>
          <div className="flex justify-content-end flex-wrap w-12 md:w-6">
            {onSearch ? (
              <div className="p-input-icon-left mx-2">
                <i className="pi pi-search" />
                <InputText
                  value={searchKeyword}
                  onChange={(e) => onSearch(e.target.value)}
                  placeholder="Search"
                />
              </div>
            ) : null}

            {linkTo ? (
              <PrimaryButton
                severity="secondary"
                className="mt-2 md:mt-0"
                onClick={() => history.push(linkTo)}
                label={buttonTitle}
              />
            ) : buttonTitle ? (
              <PrimaryButton
                severity="secondary"
                className="mt-2 md:mt-0"
                onClick={onClick}
                label={buttonTitle}
              />
            ) : null}

            {extraButtons &&
              extraButtons.length > 0 &&
              extraButtons.map((item) => (
                <>
                  {item.linkTo ? (
                    <PrimaryButton
                      severity="secondary"
                      className="mt-2 md:mt-0 ml-2"
                      onClick={() => history.push(item.linkTo)}
                      label={item.buttonTitle}
                    />
                  ) : buttonTitle ? (
                    <PrimaryButton
                      severity="secondary"
                      className="mt-2 md:mt-0 ml-2"
                      onClick={item.onClick}
                      label={item.buttonTitle}
                    />
                  ) : null}
                </>
              ))}
          </div>
        </div>
      ) : null}
      <div className="content-2 overflow-x-scroll md:overflow-x-hidden">
        <div style={{ minWidth: "700px" }}>{children}</div>
      </div>
    </div>
  );
}
