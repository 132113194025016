import { combineReducers } from "redux";
import loaderReducer from "./loaderReducer";
import profileReducer from "./profileReducer";
import toastReducer from "./toastReducer";
import dashboardReducer from "./dashboardReducer";
import customersReducer from "./customerReducer";
import locationsReducer from "./locationReducer";
import employeeReducer from "./employeeReducer";
import locationMenuReducer from "./locationMenuReducer";
import productsReducer from "./productsReducer";
import ingredientsReducer from "./ingredientsReducer";
import addOnReducer from "./addOnReducer";
import OrdersMenuReducer from "./ordersMenuReducer";

export default combineReducers({
  toast: toastReducer,
  loader: loaderReducer,
  profile: profileReducer,
  dashboard: dashboardReducer,
  customer: customersReducer,
  locations: locationsReducer,
  employee: employeeReducer,
  locationMenu: locationMenuReducer,
  products: productsReducer,
  ingredients: ingredientsReducer,
  addOns: addOnReducer,
  orders: OrdersMenuReducer,
});
