import React from "react";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom/cjs/react-router-dom.min";
import { types } from "../redux/types/types";

export default function Layout({ children }) {
  const dispatch = useDispatch();
  let { locationId, id } = useParams();
  let { allLocations } = useSelector((state) => state.locations);

  const getLocationName = () => {
    let location = allLocations.find((item) => item._id === locationId);
    if (location) {
      return location?.name;
    }
    location = allLocations.find((item) => item._id === id);
    if (location) {
      return location?.name;
    }
    return "";
  };

  useEffect(() => {
    dispatch({
      type: types.CHANGE_LOCATION_NAME,
      payload: getLocationName(),
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [locationId, id]);

  return <>{children}</>;
}
