import React from "react";
import CustomCard from "../../../shared/Card/CustomCard";
import ProductForm from "./ProductForm";

export default function EditProducts({ name }) {
  return (
    <CustomCard title={name}>
      <ProductForm type="EDIT" />
    </CustomCard>
  );
}
