import { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import { useHistory, useParams } from "react-router-dom";
// import { getCustomer, addCustomer, editCustomer } from "../../../../redux/actions/customerActions";
import { allValidations } from "../../../utils/formValidations";
import { showFormErrors } from "../../../utils/commonFunctions";
import {
  addIngredients,
  editIngredient,
  getIngredient,
} from "../../../redux/actions/ingredientsAction";

export default function IngredientContainer() {
  const dispatch = useDispatch();
  const history = useHistory();
  const { id } = useParams();

  useEffect(() => {
    if (id) {
      dispatch(
        getIngredient(id, (data) => {
          setData({
            name: data?.name,
            price: data?.price,
          });
        })
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id, dispatch]);

  const [formType, setFormType] = useState("ADD");
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState({ name: "", price: 0 });

  const handleChange = ({ name, value }) => {
    if (formType !== "VIEW") {
      const formErrors = allValidations(name, value, data);
      setData((prev) => ({ ...prev, [name]: value, formErrors }));
    }
  };

  const onSubmit = () => {
    if (showFormErrors(data, setData)) {
      if (formType === "ADD") {
        dispatch(addIngredients(data, setLoading, history));
      } else if (formType === "EDIT") {
        dispatch(editIngredient(id, data, setLoading, history));
      }
    }
  };

  return { data, handleChange, loading, onSubmit, setFormType, history };
}
