import React from "react";
import CustomCard from "../../../shared/Card/CustomCard";

import LocationForm from "./LocationForm";

export default function AddLocation({ name }) {
  return (
    <CustomCard title={name} backable>
      <LocationForm type="ADD" />
    </CustomCard>
  );
}
