import React from "react";
import InputLayout from "./InputLayout";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";

export const CustomPhoneNumber = ({
  label,
  name,
  data,
  value,
  errorMessage,
  extraClassName,
  required,
  col,
  inputClass,
  ...props
}) => {
  return (
    <InputLayout
      col={col || 6}
      label={label}
      name={name}
      required={required}
      extraClassName={extraClassName}
      data={data}
      errorMessage={errorMessage}
    >
      <PhoneInput
        id={name}
        name={name}
        value={value || data?.[name]}
        {...props}
        inputClass={`w-full ${inputClass ? inputClass : ""} ${
          errorMessage ? "p-invalid" : ""
        }`}
        {...props}
        inputStyle={{ padding: "1.3rem 3.5rem" }}
      />
    </InputLayout>
  );
};
