import Dashboard from "../views/Dashboard/Dashboard";
import Customers from "../views/Customers/Customers";
import InActiveCustomers from "../views/Customers/InActiveCustomers";
import ViewCustomerForm from "../views/Customers/ViewCustomerForm";

// import Products from "../views/Locations/Products/Products";
// import Orders from "../views/Locations/Orders/Orders";
// import Completed from "../views/Locations/Completed/Completed";

import Employees from "../views/Employees/Employees";
import ViewEmployee from "../views/Employees/EmployeeForm/ViewEmployee";
import EditEmployee from "../views/Employees/EmployeeForm/EditEmployee";
import AddEmployee from "../views/Employees/EmployeeForm/AddEmployee";

import Locations from "../views/Locations/Locations";
import ViewLocation from "../views/Locations/LocationForm/ViewLocation";
import AddLocation from "../views/Locations/LocationForm/AddLocation";
import EditLocation from "../views/Locations/LocationForm/EditLocation";

import Ingredients from "../views/Ingredients/Ingredients";
import AddIngredients from "../views/Ingredients/IngredientsForm/AddIngredients";
import EditIngredients from "../views/Ingredients/IngredientsForm/EditIngredients";
import ViewIngredients from "../views/Ingredients/IngredientsForm/ViewIngredients";

import AddOn from "../views/Addons/AddOn";
import CreateAddOn from "../views/Addons/AddOnForm/CreateAddOn";
import ViewAddOn from "../views/Addons/AddOnForm/ViewAddOn";
import EditAddOn from "../views/Addons/AddOnForm/EditAddOn";

import Menu from "../views/Menu/Menu";
import AddMenu from "../views/Menu/MenuForm/AddMenu";
import EditMenu from "../views/Menu/MenuForm/EditMenu";
import ViewMenu from "../views/Menu/MenuForm/ViewMenu";
import Products from "../views/Products/Products";
import AddProducts from "../views/Products/ProductForm/AddProducts";
import EditProducts from "../views/Products/ProductForm/EditProducts";
import ViewProducts from "../views/Products/ProductForm/ViewProducts";
import Orders from "../views/OrdersMenu/Orders";
import Profile from "../views/Profile";
import OrderDetails from "../views/OrdersMenu/OrderDetails";
import ReorderLocation from "../views/Locations/ReorderLocation";
import ReorderMenu from "../views/Menu/ReorderMenu";
import ReorderProduct from "../views/Products/ReorderProduct";

export const AdminRoutes = [
  {
    path: "/dashboard",
    name: "Dashboard",
    component: Dashboard,
    exact: true,
  },
  {
    path: "/inactive-customers",
    name: "InActive Customers",
    component: InActiveCustomers,
    exact: true,
  },
  {
    path: "/customers",
    name: "Customers",
    component: Customers,
    exact: true,
  },
  {
    path: "/customers/view/:id",
    name: "Customers",
    component: ViewCustomerForm,
    exact: true,
  },
  {
    path: "/employees",
    name: "Employees",
    component: Employees,
    exact: true,
  },
  {
    path: "/employees/add",
    name: "Add Employee",
    component: AddEmployee,
    exact: true,
  },
  {
    path: "/employees/view/:id",
    name: "View Employee Details",
    component: ViewEmployee,
    exact: true,
  },
  {
    path: "/employees/edit/:id",
    name: "Edit Employee Details",
    component: EditEmployee,
    exact: true,
  },
  {
    path: "/locations",
    name: "All Locations",
    component: Locations,
    exact: true,
  },
  {
    path: "/locations/add",
    name: "Add Location Details",
    component: AddLocation,
    exact: true,
  },
  {
    path: "/locations/edit/:id",
    name: "Edit Location Details",
    component: EditLocation,
    exact: true,
  },
  {
    path: "/locations/view/:id",
    name: "View Location Details",
    component: ViewLocation,
    exact: true,
  },
  {
    path: "/locations/reorder",
    name: "Reorder Location",
    component: ReorderLocation,
    exact: true,
  },
  {
    path: "/menu/:id",
    name: "Menu List",
    component: Menu,
    exact: true,
  },
  {
    path: "/menu/reorder/:id",
    name: "Reorder Menu",
    component: ReorderMenu,
    exact: true,
  },
  {
    path: "/menu/add/:locationId",
    name: "Add New Menu",
    component: AddMenu,
    exact: true,
  },
  {
    path: "/menu/view/:id",
    name: "View Menu Details",
    component: ViewMenu,
    exact: true,
  },
  {
    path: "/menu/edit/:id",
    name: "Edit Menu Details",
    component: EditMenu,
    exact: true,
  },
  {
    path: "/products/:id",
    name: "Products",
    component: Products,
    exact: true,
  },
  {
    path: "/products/reorder/:id",
    name: "Reorder Products",
    component: ReorderProduct,
    exact: true,
  },
  {
    path: "/products/add/:locationId",
    name: " Enter Products Details",
    component: AddProducts,
    exact: true,
  },

  {
    path: "/products/edit/:locationId/:id",
    name: "Edit Products Details",
    component: EditProducts,
    exact: true,
  },
  {
    path: "/products/view/:locationId/:id",
    name: "View Products Details",
    component: ViewProducts,
    exact: true,
  },
  {
    path: "/orders/:id",
    name: "Latest Orders",
    component: Orders,
    exact: true,
  },
  {
    path: "/orders-details/:id",
    name: "Orders Details",
    component: OrderDetails,
    exact: true,
  },
  {
    path: "/ingredients",
    name: "Ingredients",
    component: Ingredients,
    exact: true,
  },
  {
    path: "/ingredients/add",
    name: "Add Ingredient",
    component: AddIngredients,
    exact: true,
  },
  {
    path: "/ingredients/view/:id",
    name: "View Ingredients Details",
    component: ViewIngredients,
    exact: true,
  },
  {
    path: "/ingredients/edit/:id",
    name: "Edit Ingredients Details",
    component: EditIngredients,
    exact: true,
  },
  {
    path: "/addons",
    name: "Add-Ons",
    component: AddOn,
    exact: true,
  },
  {
    path: "/addons/add",
    name: "Create Add-On",
    component: CreateAddOn,
    exact: true,
  },
  {
    path: "/addons/view/:id",
    name: "View Add-On Details",
    component: ViewAddOn,
    exact: true,
  },
  {
    path: "/addons/edit/:id",
    name: "Edit Add-On Details",
    component: EditAddOn,
    exact: true,
  },

  {
    path: "/settings",
    name: "Settings",
    component: Profile,
    exact: true,
  },
];

export const ManagerRoutes = [
  {
    path: "/dashboard",
    name: "Dashboard",
    component: Dashboard,
    exact: true,
  },
  {
    path: "/locations",
    name: "All Locations",
    component: Locations,
    exact: true,
  },
  {
    path: "/locations/add",
    name: "Add Location Details",
    component: AddLocation,
    exact: true,
  },
  {
    path: "/locations/edit/:id",
    name: "Edit Location Details",
    component: EditLocation,
    exact: true,
  },
  {
    path: "/locations/view/:id",
    name: "View Location Details",
    component: ViewLocation,
    exact: true,
  },
  {
    path: "/locations/reorder",
    name: "Reorder Location",
    component: ReorderLocation,
    exact: true,
  },
  {
    path: "/orders/:id",
    name: "Latest Orders",
    component: Orders,
    exact: true,
  },
  {
    path: "/orders-details/:id",
    name: "Orders Details",
    component: OrderDetails,
    exact: true,
  },
  {
    path: "/settings",
    name: "Settings",
    component: Profile,
    exact: true,
  },
];
