const menuItems = (locations, role) => {
  if (role === "ADMIN") {
    return [
      {
        items: [
          {
            icon: "pi pi-home",
            label: "Home",
            to: "/dashboard",
          },
          {
            icon: "pi pi-user ",
            label: "Customers",
            to: "/customers",
          },
          {
            icon: "pi pi-users",
            label: "Employees",
            to: "/employees",
          },
          {
            icon: "pi  pi-sitemap",
            label: "Locations",
            to: "/locations",
            items: locations.map((item) => ({
              icon: "pi pi-map-marker",
              label: item?.name,
              items: [
                {
                  icon: "pi pi-map",
                  label: "Menu",
                  to: `/menu/${item._id}`,
                },
                {
                  icon: "pi pi-book",
                  label: "Products",
                  to: `/products/${item._id}`,
                },
                {
                  icon: "pi pi-cart-plus",
                  label: "Latest Orders",
                  to: `/orders/${item._id}`,
                },
                {
                  icon: "pi pi-box",
                  label: "Orders Details",
                  to: `/orders-details/${item._id}`,
                },
              ],
            })),
          },
          {
            icon: "pi pi-th-large",
            label: "Ingredients",
            to: "/ingredients",
          },
          {
            icon: "pi pi-cart-plus",
            label: "Add On",
            to: "/addons",
          },
          {
            icon: "pi pi-cog",
            label: "Settings",
            to: "/settings",
          },
        ],
      },
    ];
  } else {
    return [
      {
        items: [
          {
            icon: "pi pi-home",
            label: "Home",
            to: "/dashboard",
          },
          {
            icon: "pi  pi-sitemap",
            label: "Locations",
            to: "/locations",
            items: locations.map((item) => ({
              icon: "pi pi-map-marker",
              label: item?.name,
              items: [
                {
                  icon: "pi pi-cart-plus",
                  label: "Latest Orders",
                  to: `/orders/${item._id}`,
                },
                {
                  icon: "pi pi-box",
                  label: "Orders Details",
                  to: `/orders-details/${item._id}`,
                },
              ],
            })),
          },
          {
            icon: "pi pi-cog",
            label: "Settings",
            to: "/settings",
          },
        ],
      },
    ];
  }
};

export { menuItems };
