import { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useParams } from "react-router-dom";
// import { getCustomer, addCustomer, editCustomer } from "../../../../redux/actions/customerActions";
import { allValidations } from "../../../utils/formValidations";
import { showFormErrors } from "../../../utils/commonFunctions";
import { getLocationsMenu } from "../../../redux/actions/locationMenuAction";
import { getIngredients } from "../../../redux/actions/ingredientsAction";
import { getAddOns } from "../../../redux/actions/addOnAction";
import {
  addProducts,
  editProducts,
  getProduct,
  getR2OProducts,
} from "../../../redux/actions/productsAction";

export default function ProductsContainer() {
  const dispatch = useDispatch();
  const history = useHistory();

  const { id, locationId } = useParams();
  useEffect(() => {
    if (locationId) {
      dispatch(getR2OProducts(locationId));
    }
    dispatch(getLocationsMenu(setLoading, locationId));
    dispatch(getIngredients());
    dispatch(getAddOns());
  }, [dispatch, locationId]);

  useEffect(() => {
    if (id) {
      dispatch(
        getProduct(id, (data) => {
          console.log(data);
          const ingredientsWithIds = data.ingredients.map((ingredient) => {
            return ingredient._id;
          });
          const addOnsIds = data.addOns.map((addOn) => {
            return addOn._id;
          });
          setData({
            name: data.name,
            category: data.category,
            price: data.price,
            ingredients: ingredientsWithIds,
            addOns: addOnsIds,
            readyToOrderId: data.readyToOrderId,
            tax: data.tax,
            showOnApp: data.showOnApp,
          });
        })
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id, dispatch]);

  const [formType, setFormType] = useState("ADD");
  const [loading, setLoading] = useState(false);

  let { readyToOrderProducts } = useSelector((state) => state.products);
  let { allLocationsMenu } = useSelector((state) => state.locationMenu);
  let { allIngredients } = useSelector((state) => state.ingredients);
  let { allAddOns } = useSelector((state) => state.addOns);
  let categoryDropDown = allLocationsMenu.map((item) => ({
    _id: item._id,
    name: item.name,
  }));

  let Ingredients = allIngredients.map((item) => ({
    name: item.name,
    _id: item._id,
    price: item.price,
    checked: true,
  }));

  let AddOn = allAddOns.map((item) => ({
    name: item.name,
    _id: item._id,
    price: item.price,
  }));

  const [data, setData] = useState({
    name: "",
    category: "",
    price: 0,
    readyToOrderId: "",
    ingredients: [],
    addOns: [],
    showOnApp: true,
    tax: 10,
  });
  const handleChange = ({ name, value }) => {
    if (formType !== "VIEW") {
      const formErrors = allValidations(name, value, data);
      setData((prev) => ({ ...prev, [name]: value, formErrors }));
    }
  };
  const handleIngredients = (event, id) => {
    const isChecked = event.target.checked;
    setData((prev) => ({
      ...prev,
      ingredients: isChecked
        ? [...prev.ingredients, id]
        : prev.ingredients.filter((item) => item !== id),
    }));
  };
  const handleAddOns = (event, id) => {
    const isChecked = event.target.checked;
    setData((prev) => ({
      ...prev,
      addOns: isChecked
        ? [...prev.addOns, id]
        : prev.addOns.filter((item) => item !== id),
    }));
  };

  const onSubmit = () => {
    if (showFormErrors(data, setData)) {
      if (formType === "ADD") {
        dispatch(addProducts(data, setLoading, history, locationId));
      } else if (formType === "EDIT") {
        dispatch(editProducts(id, data, setLoading, history, locationId));
      }
    }
  };

  return {
    data,
    handleChange,
    loading,
    onSubmit,
    setFormType,
    categoryDropDown,
    Ingredients,
    AddOn,
    handleIngredients,
    handleAddOns,
    readyToOrderProducts,
  };
}
