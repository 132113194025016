import React, { useEffect } from "react";
import {
  CustomCheckBox,
  CustomDropDown,
  CustomForm,
  CustomInput,
  CustomInputNumber,
} from "../../../shared/Input/AllInputs";
import PrimaryButton from "../../../shared/Button/PrimaryButton";
import ProductsContainer from "./ProductsContainer";

export default function ProductForm({ type }) {
  const {
    data,
    handleChange,
    loading,
    onSubmit,
    setFormType,
    categoryDropDown,
    Ingredients,
    AddOn,
    handleIngredients,
    handleAddOns,
    readyToOrderProducts,
  } = ProductsContainer();
  useEffect(() => {
    setFormType(type);
  }, [type, setFormType]);

  return (
    <>
      <CustomForm style={type === "VIEW" ? { pointerEvents: "none" } : {}}>
        <CustomDropDown
          label="Menu Name"
          name="category"
          required
          data={data}
          options={categoryDropDown}
          placeholder="Select a Menu"
          onChange={handleChange}
        />
        <CustomInput
          label="Products Name"
          name="name"
          required
          data={data}
          onChange={handleChange}
        />
        <CustomInputNumber
          label="Product Price"
          name="price"
          required
          data={data}
          onChange={handleChange}
          showButtons
          min={0}
          mode="currency"
          currency="EUR"
          buttonLayout="horizontal"
        />
        <CustomDropDown
          label="Ready2Order Product"
          name="readyToOrderId"
          required
          data={data}
          options={readyToOrderProducts}
          placeholder="Select a Menu"
          onChange={handleChange}
        />
        <CustomInputNumber
          label="Tax"
          name="tax"
          required
          data={data}
          onChange={handleChange}
          showButtons
          min={0}
          suffix="%"
          buttonLayout="horizontal"
        />
        <CustomCheckBox
          data={data}
          name="showOnApp"
          label="Show Product In App"
          onChange={handleChange}
          checked={data?.showOnApp}
        />
        <div className="col-12 md:col-6">
          <label className="mb-4">Select Ingredients</label>
          {Ingredients.map((item) => {
            return (
              <div className="flex align-items-center">
                <CustomCheckBox
                  col="1"
                  inputId={item.name + "in"}
                  name={item._id}
                  value={item._id}
                  checked={data?.ingredients.includes(item._id)}
                  onChange={(event) => handleIngredients(event, item._id)}
                />
                <label htmlFor={item.name + "in"} className="ml-2 mb-0">
                  {item.name} ({item.price})
                </label>
              </div>
            );
          })}
        </div>
        <div className="field col-12 md:col-5">
          <label>Select Adds-On</label>

          {AddOn.map((item) => {
            return (
              <div className="flex align-items-center">
                <CustomCheckBox
                  col="1"
                  inputId={item.name + "ad"}
                  name={item._id}
                  value={item._id}
                  checked={data?.addOns.includes(item._id)}
                  onChange={(event) => handleAddOns(event, item._id)}
                />{" "}
                <label htmlFor={item.name + "ad"} className="my-auto">
                  {item.name} ({item.price})
                </label>
              </div>
            );
          })}
        </div>

        {type !== "VIEW" && (
          <PrimaryButton
            label="Save Product"
            loading={loading}
            onClick={onSubmit}
          />
        )}
      </CustomForm>
    </>
  );
}
