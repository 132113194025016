import api from "../../services/api";
import { types } from "../types/types";
import Constants from "../../services/constant";
import { showToast } from "./toastAction";
import { showLoaderAction, hideLoaderAction } from "./loaderAction";

const getEmployees = (setLoading) => async (dispatch) => {
  if (setLoading) {
    setLoading(true);
  }
  const res = await api("get", Constants.END_POINT.EMPLOYEE);
  if (res.success) {
    if (res.data) {
      dispatch({
        type: types.CHANGE_EMPLOYEES,
        payload: res.data,
      });
    }
  }
  if (setLoading) {
    setLoading(false);
  }
};
const getEmployee = (id, returnData) => async (dispatch) => {
  dispatch(showLoaderAction());
  const res = await api("get", Constants.END_POINT.EMPLOYEE + id);
  if (res.success) {
    dispatch(hideLoaderAction());
    if (res.data) {
      if (returnData) {
        returnData(res.data);
      }
    }
  }
  dispatch(hideLoaderAction());
};

const addEmployee = (data, setLoading, history) => async (dispatch) => {
  setLoading(true);
  const payload = {
    ...data,
  };
  const res = await api("post", Constants.END_POINT.EMPLOYEE, payload);
  if (res.success) {
    history.goBack();
    dispatch(showToast({ severity: "success", summary: res.message }));
  } else {
    dispatch(showToast({ severity: "error", summary: res.message }));
  }
  setLoading(false);
};
const editEmployee = (id, data, setLoading, history) => async (dispatch) => {
  setLoading(true);

  const payload = {
    ...data,
  };

  const res = await api("put", Constants.END_POINT.EMPLOYEE + id, payload);
  if (res.success) {
    history.goBack();
    dispatch(showToast({ severity: "success", summary: res.message }));
  } else {
    dispatch(showToast({ severity: "error", summary: res.message }));
  }
  setLoading(false);
};
const EmployeeEnableDisableAction = (id) => async (dispatch) => {
  const res = await api(
    "get",
    Constants.END_POINT.ENABLE_DISABLE_EMPLOYEE + id
  );
  if (res.success) {
    dispatch(getEmployees());
    dispatch(showToast({ severity: "success", summary: res.message }));
  } else {
    dispatch(showToast({ severity: "error", summary: res.message }));
  }
};

export {
  getEmployees,
  addEmployee,
  EmployeeEnableDisableAction,
  getEmployee,
  editEmployee,
};
