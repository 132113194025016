import { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import { useHistory, useParams } from "react-router-dom";
// import { getCustomer, addCustomer, editCustomer } from "../../../../redux/actions/customerActions";
import { allValidations } from "../../../utils/formValidations";
import { showFormErrors } from "../../../utils/commonFunctions";
import {
  addMenu,
  editMenu,
  getMenu,
} from "../../../redux/actions/locationMenuAction";

export default function MenuContainer() {
  const dispatch = useDispatch();
  const history = useHistory();

  const { id, locationId } = useParams();

  useEffect(() => {
    if (id) {
      dispatch(
        getMenu(id, (data) => {
          setData({
            name: data.name,
            image: data.image ? [data.image] : [],
            applicablePreparationTime: data.applicablePreparationTime,
            color: data.color,
          });
        })
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id, dispatch]);

  const [formType, setFormType] = useState("ADD");
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState({
    name: "",
    image: [],
    applicablePreparationTime: false,
    color: "#000000",
  });

  const handleChange = ({ name, value }) => {
    if (formType !== "VIEW") {
      const formErrors = allValidations(name, value, data);
      setData((prev) => ({ ...prev, [name]: value, formErrors }));
    }
  };

  const onSubmit = () => {
    if (showFormErrors(data, setData)) {
      if (formType === "ADD") {
        dispatch(addMenu(data, setLoading, history, locationId));
      } else if (formType === "EDIT") {
        dispatch(editMenu(id, data, setLoading, history, locationId));
      }
    }
  };

  return { data, handleChange, loading, onSubmit, setFormType };
}
