import React from "react";
import CustomCard from "../../../shared/Card/CustomCard";
import IngredientsForm from "./IngredientsForm";

export default function EditIngredients({ name }) {
  return (
    <CustomCard title={name}>
      <IngredientsForm type="EDIT" />
    </CustomCard>
  );
}
