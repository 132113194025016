import React from "react";
import { AdminRoutes, ManagerRoutes } from "./allRoutes";
import { Route, Redirect } from "react-router-dom";
import { getMyRole, isAuthenticated } from "../services/auth";
import { Switch, useParams } from "react-router-dom/cjs/react-router-dom.min";
import NotFound from "../views/NotFound";
import Layout from "../shared/Layout";
import { useSelector } from "react-redux";

export default function ProtectedRoute({ socket }) {
  let { id, locationId } = useParams();
  let { allLocations } = useSelector((state) => state.locations);

  const getLocationName = () => {
    let location = allLocations.find((item) => item._id === locationId || id);
    return location?.name;
  };
  const getAuthRoutes = (routes) => {
    return routes.map((prop, key) => {
      return isAuthenticated() ? (
        <Route
          path={prop.path}
          key={key}
          render={(e) => (
            <Layout>
              <prop.component
                {...e}
                name={prop.name}
                getLocationName={getLocationName}
                socket={socket}
              />
            </Layout>
          )}
          exact={prop?.exact}
        />
      ) : (
        <Redirect to="/" />
      );
    });
  };
  return (
    <Switch>
      {getMyRole() === "ADMIN"
        ? getAuthRoutes(AdminRoutes)
        : getAuthRoutes(ManagerRoutes)}
      <Route path="*" render={(e) => <NotFound {...e} socket={socket} />} />
    </Switch>
  );
}
