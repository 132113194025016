import React from "react";
import { useHistory } from "react-router-dom";
import { confirmPopup } from "primereact/confirmpopup";
import { logout } from "./services/auth";
import { useSelector } from "react-redux";
export default function AppTopBar({ toggle, socket }) {
  const history = useHistory();
  let { name } = useSelector((state) => state.locations);

  const confirm = (event) => {
    confirmPopup({
      target: event.currentTarget,
      message: "Are you sure you want to Logout?",
      icon: "pi pi-exclamation-triangle",
      accept,
      reject,
    });
  };
  const accept = () => {
    logout(() => {
      socket.disconnect();
      setTimeout(() => {
        history.push("/login");
      }, 400);
    });
  };
  const reject = () => {};

  return (
    <div className="layout-topbar">
      <div>
        <i
          className="pi pi-bars layout-topbar-button text-xl"
          onClick={toggle}
        ></i>
        <h4 className="my-auto mx-2">DENIS KEBAP</h4>
      </div>
      <div>
        {name ? <span className="mx-5 text-xl my-auto">{name}</span> : null}
        <i className="pi pi-power-off my-auto text-2xl" onClick={confirm} />
      </div>
    </div>
  );
}
