import { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useHistory, useParams } from "react-router-dom";
import {
  deleteMenuAction,
  getLocationsMenu,
  reorderMenu,
} from "../../redux/actions/locationMenuAction";
import { confirmDelete } from "../../utils/commonFunctions";

export default function Index() {
  const history = useHistory();
  const dispatch = useDispatch();
  const { id } = useParams();
  useEffect(() => {
    dispatch(getLocationsMenu(setLoading, id));
  }, [dispatch, id]);

  let { allLocationsMenu } = useSelector((state) => state.locationMenu);

  const [loading, setLoading] = useState(false);

  const [search, setSearch] = useState("");
  const getFilteredData = (data, search) => {
    if (search.length) {
      search = search.toLowerCase();
      data = data.filter((item) => item?.name?.toLowerCase().includes(search));
    }
    return data;
  };
  const onDelete = (categoryId, position) => {
    confirmDelete(
      () => {
        dispatch(deleteMenuAction(categoryId, id));
      },
      "Do you want to delete this Menu?",
      position
    );
  };

  const [reorderd, setReorderd] = useState([]);

  useEffect(() => {
    setReorderd(allLocationsMenu);
  }, [allLocationsMenu]);

  const handleChnage = (e) => {
    setReorderd(e.value);
  };

  const onSave = () => {
    dispatch(
      reorderMenu(reorderd, setLoading, () => {
        history.push("/menu/" + id);
      })
    );
  };

  allLocationsMenu = getFilteredData(allLocationsMenu, search);
  return {
    allLocationsMenu,
    loading,
    history,
    search,
    setSearch,
    id,
    onDelete,
    handleChnage,
    reorderd,
    onSave,
  };
}
