import React from "react";
import TableCard from "../../shared/TableCard/TableCard";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { InputSwitch } from "primereact/inputswitch";
import { useDispatch } from "react-redux";
import moment from "moment";
import { EmployeeEnableDisableAction } from "../../redux/actions/employeeAction";

export default function EmployeeTable({
  allEmployees,
  loading,
  history,
  search,
  setSearch,
}) {
  const dispatch = useDispatch();
  const actions = (d) => {
    return (
      <div className="flex">
        <i
          className="pi pi-eye cursor-pointer mx-2"
          onClick={() => history.push(`/employees/view/${d._id}`)}
        ></i>
        <i
          className="pi pi-pencil cursor-pointer mx-2"
          onClick={() => history.push(`/employees/edit/${d._id}`)}
        ></i>
      </div>
    );
  };

  const handleSwitchChange = (id) => {
    dispatch(EmployeeEnableDisableAction(id));
  };
  const switchTemplate = (rowData) => {
    return (
      <div className="flex">
        <InputSwitch
          checked={rowData.isActive}
          onChange={() => handleSwitchChange(rowData?._id)}
        />
        <p style={{ marginLeft: "5px" }}>{rowData?.isActive ? "On" : "Off"}</p>
      </div>
    );
  };

  return (
    <TableCard
      title="Employee List"
      onSearch={setSearch}
      searchKeyword={search}
      buttonTitle="Add Employee"
      linkTo="/employees/add"
    >
      <DataTable
        paginator
        value={allEmployees}
        className=""
        showGridlines
        rows={10}
        loading={loading}
        responsiveLayout="scroll"
        emptyMessage="No customer found."
      >
        <Column field="name" header="Name" style={{ width: "250px" }}></Column>
        <Column
          field="email"
          header="Email"
          style={{ width: "250px" }}
        ></Column>
        <Column
          field="mobile"
          header="Mobile"
          style={{ width: "250px" }}
        ></Column>
        <Column
          field="createdon"
          body={(rowData) => moment(rowData.time).format("DD-MM-YYYY")}
          header="Created On"
          style={{ width: "250px" }}
        ></Column>
        <Column field="role" header="Role" style={{ width: "250px" }}></Column>
        <Column
          field="status"
          header="Status"
          body={switchTemplate}
          style={{ width: "250px" }}
        ></Column>
        <Column
          body={actions}
          style={{ width: "250px" }}
          header="Action"
        ></Column>
      </DataTable>
    </TableCard>
  );
}
