import { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import {
  getLocations,
  reorderLocation,
} from "../../redux/actions/locationAction";

export default function Index() {
  const history = useHistory();
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(getLocations(setLoading));
  }, [dispatch]);
  //   let allLocations = [];

  let { allLocations } = useSelector((state) => state.locations);

  const [loading, setLoading] = useState(false);

  const [search, setSearch] = useState("");

  const getFilteredData = (data, search) => {
    console.log(data);
    if (search.length) {
      search = search.toLowerCase();
      data = data.filter(
        (item) =>
          item?.name?.toLowerCase().includes(search) ||
          item?.address?.toLowerCase().includes(search) ||
          item?.tagLine?.toLowerCase().includes(search)
      );
    }
    return data;
  };

  const [reorderd, setReorderd] = useState([]);

  useEffect(() => {
    setReorderd(allLocations);
  }, [allLocations]);
  const handleChnage = (e) => {
    setReorderd(e.value);
  };
  const onSave = () => {
    dispatch(
      reorderLocation(reorderd, setLoading, () => {
        history.push("/locations");
      })
    );
  };

  allLocations = getFilteredData(allLocations, search);
  return {
    allLocations,
    loading,
    history,
    search,
    setSearch,
    setLoading,
    handleChnage,
    reorderd,
    onSave,
  };
}
