import api from "../../services/api";
import { types } from "../types/types";
import Constants from "../../services/constant";
import { showToast } from "./toastAction";
import { showLoaderAction, hideLoaderAction } from "./loaderAction";
import { uploadImages } from "../../utils/uploadFiles";

const getLocationsMenu = (setLoading, id) => async (dispatch) => {
  if (setLoading) {
    setLoading(true);
  }
  const res = await api("get", Constants.END_POINT.MENU + `?location=${id}`);
  if (res.success) {
    if (res.data) {
      dispatch({
        type: types.CHANGE_LOCATIONS_MENU,
        payload: res.data,
      });
    }
  }
  if (setLoading) {
    setLoading(false);
  }
};

const LocationMenuEnableDisableAction =
  (id, locationId, active) => async (dispatch) => {
    const payload = { isActive: active };
    const res = await api("put", Constants.END_POINT.MENU + id, payload);
    if (res.success) {
      dispatch(getLocationsMenu(null, locationId));
      dispatch(showToast({ severity: "success", summary: res.message }));
    }
  };
const addMenu = (data, setLoading, history, locationId) => async (dispatch) => {
  setLoading(true);

  if (data.image.length) {
    let urls = await uploadImages(data.image);
    data.image = urls[0];
  } else {
    data.image = "";
  }

  const payload = {
    ...data,
    location: locationId,
  };

  const res = await api("post", Constants.END_POINT.MENU, payload);
  if (res.success) {
    getLocationsMenu(null, locationId);
    history.goBack();
    dispatch(showToast({ severity: "success", summary: res.message }));
  }
  setLoading(false);
};
const editMenu =
  (id, data, setLoading, history, locationId) => async (dispatch) => {
    setLoading(true);

    if (data.image.length) {
      let urls = await uploadImages(data.image);
      data.image = urls[0];
    } else {
      data.image = "";
    }

    const payload = {
      ...data,
      location: locationId,
    };

    const res = await api("put", Constants.END_POINT.MENU + id, payload);
    if (res.success) {
      getLocationsMenu(null, locationId);
      history.goBack();
      dispatch(showToast({ severity: "success", summary: res.message }));
    }
    setLoading(false);
  };

export const deleteMenuAction = (id, locationId) => async (dispatch) => {
  const res = await api("delete", Constants.END_POINT.MENU + id);
  if (res.success) {
    dispatch(getLocationsMenu(null, locationId));
    dispatch(showToast({ severity: "success", summary: res.message }));
  } else {
    dispatch(showToast({ severity: "error", summary: res.message }));
  }
};
const getMenu = (id, returnData) => async (dispatch) => {
  dispatch(showLoaderAction());
  const res = await api("get", Constants.END_POINT.MENU + id);
  if (res.success) {
    dispatch(hideLoaderAction());
    if (res.data) {
      if (returnData) {
        returnData(res.data);
      }
    }
  }
  dispatch(hideLoaderAction());
};

const reorderMenu = (locations, setLoading, next) => async (dispatch) => {
  let payload = locations.map((item) => item._id);
  setLoading(true);
  const res = await api("post", Constants.END_POINT.MENU + "reorder", payload);
  setLoading(false);
  if (res.success) {
    if (next) {
      next();
    }
  }
};

export {
  getLocationsMenu,
  LocationMenuEnableDisableAction,
  addMenu,
  getMenu,
  editMenu,
  reorderMenu,
};
