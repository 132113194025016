import React from "react";
import CustomCard from "../../../shared/Card/CustomCard";
import LocationForm from "./LocationForm";

export default function ViewLocation({ name }) {
  return (
    <CustomCard title={name} backable>
      <LocationForm type="VIEW" />
    </CustomCard>
  );
}
