import React, { useEffect } from "react";
import {
  CustomDropDown,
  CustomForm,
  CustomInput,
  CustomInputColor,
  CustomPassword,
} from "../../../shared/Input/AllInputs";
import EmployeeContainer from "./EmployeeContainer";

import PrimaryButton from "../../../shared/Button/PrimaryButton";
import { CustomPhoneNumber } from "../../../shared/Input/CustomPhoneInput";

export default function EmployeeForm({ type }) {
  const {
    data,
    handleChange,
    loading,
    onSubmit,
    setFormType,
    role,
    PhoneInputChange,
  } = EmployeeContainer();

  useEffect(() => {
    setFormType(type);
  }, [type, setFormType]);

  return (
    <div>
      <CustomForm style={type === "VIEW" ? { pointerEvents: "none" } : {}}>
        <CustomInput
          label="Name"
          name="name"
          required
          data={data}
          onChange={handleChange}
        />
        <CustomInput
          label="Email Address"
          required
          data={data}
          name="email"
          onChange={handleChange}
        />
        {type === "ADD" ? (
          <>
            <CustomPassword
              name="password"
              label="Password"
              required
              data={data}
              onChange={handleChange}
            />
            <CustomPassword
              name="confirmPassword"
              label="Confirm Password"
              required
              data={data}
              onChange={handleChange}
            />
          </>
        ) : null}
        <CustomPhoneNumber
          label="Mobile Number"
          country={"at"}
          className="marginBottom"
          value={data?.countryCode + data.mobile}
          onChange={PhoneInputChange}
        />
        <CustomDropDown
          name="role"
          label="Role"
          options={role}
          placeholder="Select a Role"
          required
          data={data}
          onChange={handleChange}
          disabled={type === "EDIT"}
        />
        <CustomInputColor
          label="Employee Color"
          name="color"
          data={data}
          onChange={handleChange}
        />
        {type !== "VIEW" && (
          <PrimaryButton label="Save" loading={loading} onClick={onSubmit} />
        )}
      </CustomForm>
    </div>
  );
}
