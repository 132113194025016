import React from "react";
import TableCard from "../../shared/TableCard/TableCard";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { InputSwitch } from "primereact/inputswitch";
import { useDispatch } from "react-redux";
import moment from "moment";
import { AddOnEnableDisableAction } from "../../redux/actions/addOnAction";

export default function AddOnTable({
  allAddOns,
  loading,
  history,
  search,
  setSearch,
  onDelete,
}) {
  const dispatch = useDispatch();
  const actions = (d) => {
    return (
      <div className="flex justify-content-around">
        <i
          className="pi pi-eye cursor-pointer"
          onClick={() => history.push(`/addons/view/${d._id}`)}
        ></i>
        <i
          className="pi pi-pencil cursor-pointer"
          onClick={() => history.push(`/addons/edit/${d._id}`)}
        ></i>
        <i
          className="pi pi-trash cursor-pointer"
          onClick={() => onDelete(d._id)}
        ></i>
      </div>
    );
  };

  const handleSwitchChange = (id, active) => {
    dispatch(AddOnEnableDisableAction(id, active));
  };
  const switchTemplate = (rowData) => {
    return (
      <div className="flex">
        <InputSwitch
          checked={rowData.isActive}
          onChange={(e) => handleSwitchChange(rowData?._id, e.value)}
        />
        <p style={{ marginLeft: "5px" }}>{rowData?.isActive ? "On" : "Off"}</p>
      </div>
    );
  };

  return (
    <TableCard
      title="Adds-On List"
      onSearch={setSearch}
      searchKeyword={search}
      buttonTitle="Add Add-On"
      linkTo="/addons/add"
    >
      <DataTable
        paginator
        value={allAddOns}
        className=""
        showGridlines
        rows={10}
        loading={loading}
        responsiveLayout="scroll"
        emptyMessage="No AddOn found."
      >
        <Column field="name" header="Name" style={{ width: "250px" }}></Column>
        <Column
          field="price"
          header="Price"
          style={{ width: "250px" }}
        ></Column>
        <Column
          field="createdon"
          body={(rowData) => moment(rowData.time).format("DD-MM-YYYY")}
          header="Created On"
          style={{ width: "250px" }}
        ></Column>
        <Column
          field="status"
          header="Status"
          body={switchTemplate}
          style={{ width: "250px" }}
        ></Column>
        <Column
          body={actions}
          style={{ width: "250px" }}
          header="Action"
        ></Column>
      </DataTable>
    </TableCard>
  );
}
