import { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import { confirmDelete } from "../../utils/commonFunctions";
import { deleteAddOn, getAddOns } from "../../redux/actions/addOnAction";

export default function Index() {
  const history = useHistory();
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(getAddOns(setLoading));
  }, [dispatch]);

  let { allAddOns } = useSelector((state) => state.addOns);

  const [loading, setLoading] = useState(false);

  const [search, setSearch] = useState("");
  const getFilteredData = (data, search) => {
    if (search.length) {
      search = search.toLowerCase();
      data = data.filter((item) => item?.name?.toLowerCase().includes(search));
    }
    return data;
  };
  const onDelete = (id, position) => {
    confirmDelete(
      () => {
        dispatch(deleteAddOn(id));
      },
      "Do you want to delete this AddOn?",
      position
    );
  };

  allAddOns = getFilteredData(allAddOns, search);
  return { allAddOns, loading, history, search, setSearch, onDelete };
}
