import api from "../../services/api";
import { types } from "../types/types";
import Constants from "../../services/constant";
import { showToast } from "./toastAction";
import { showLoaderAction, hideLoaderAction } from "./loaderAction";

const getAddOns = (setLoading) => async (dispatch) => {
  if (setLoading) {
    setLoading(true);
  }
  const res = await api("get", Constants.END_POINT.ADD_ON);
  if (res.success) {
    if (res.data) {
      dispatch({
        type: types.CHANGE_ADDON,
        payload: res.data,
      });
    }
  }
  if (setLoading) {
    setLoading(false);
  }
};

const AddOnEnableDisableAction = (id, active) => async (dispatch) => {
  const payload = { isActive: active };
  const res = await api("put", Constants.END_POINT.ADD_ON + id, payload);
  if (res.success) {
    dispatch(getAddOns());
    dispatch(showToast({ severity: "success", summary: res.message }));
  } else {
    dispatch(showToast({ severity: "error", summary: res.message }));
  }
};

const createAddOn = (data, setLoading, history) => async (dispatch) => {
  setLoading(true);

  const res = await api("post", Constants.END_POINT.ADD_ON, data);
  if (res.success) {
    dispatch(getAddOns());
    history.goBack();
    dispatch(showToast({ severity: "success", summary: res.message }));
  }
  setLoading(false);
};
const editAddOn = (id, data, setLoading, history) => async (dispatch) => {
  setLoading(true);

  const res = await api("put", Constants.END_POINT.ADD_ON + id, data);
  if (res.success) {
    dispatch(getAddOns());
    history.goBack();
    dispatch(showToast({ severity: "success", summary: res.message }));
  }
  setLoading(false);
};
const getAddOn = (id, returnData) => async (dispatch) => {
  dispatch(showLoaderAction());
  const res = await api("get", Constants.END_POINT.ADD_ON + id);
  if (res.success) {
    if (res.data) {
      if (returnData) {
        returnData(res.data);
      }
    }
  }
  dispatch(hideLoaderAction());
};
const deleteAddOn = (id) => async (dispatch) => {
  const res = await api("delete", Constants.END_POINT.ADD_ON + id);
  if (res.success) {
    dispatch(getAddOns());
    dispatch(showToast({ severity: "success", summary: res.message }));
  } else {
    dispatch(showToast({ severity: "error", summary: res.message }));
  }
};

export {
  getAddOns,
  AddOnEnableDisableAction,
  createAddOn,
  editAddOn,
  getAddOn,
  deleteAddOn,
};
