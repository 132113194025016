import React from "react";
import CustomCard from "../../../shared/Card/CustomCard";
import EmployeeForm from "./EmployeeForm";

export default function ViewEmployee({ name }) {
  return (
    <CustomCard title={name} backable>
      <EmployeeForm type="VIEW" />
    </CustomCard>
  );
}
