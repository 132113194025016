import React from "react";
import CustomCard from "../../../shared/Card/CustomCard";
import ProductForm from "./ProductForm";

export default function ViewProducts({ name }) {
  return (
    <CustomCard title={name}>
      <ProductForm type="VIEW" />
    </CustomCard>
  );
}
