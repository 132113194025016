import React, { useEffect, useState } from "react";
import { CustomForm, CustomInput } from "../../shared/Input/AllInputs";
import CustomCard from "../../shared/Card/CustomCard";
import { useParams } from "react-router-dom/cjs/react-router-dom.min";
import { useDispatch } from "react-redux";
import { getCustomer } from "../../redux/actions/customerAction";

const ViewCustomerForm = () => {
  const { id } = useParams();
  const dispatch = useDispatch();

  const [customer, setCustomer] = useState({
    fullName: "",
    email: "",
    password: "",
    confirmPassword: "",
    mobile: "",
    role: "",
  });
  useEffect(() => {
    if (id) {
      dispatch(
        getCustomer(id, (data) => {
          setCustomer({
            fullName: data.name,
            email: data.email,
            mobile: data.mobile,
          });
        })
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id, dispatch]);

  return (
    <CustomCard title="View Customer Detail" backable>
      <CustomForm>
        <CustomInput label="Full Name" value={customer?.fullName} disabled />
        <CustomInput label="Email Address" value={customer?.email} disabled />
        <CustomInput label="Mobile Number" value={customer?.mobile} disabled />
      </CustomForm>
    </CustomCard>
  );
};

export default ViewCustomerForm;
