import React, { useEffect } from "react";
import {
  CustomForm,
  CustomInput,
  CustomInputNumber,
} from "../../../shared/Input/AllInputs";

import PrimaryButton from "../../../shared/Button/PrimaryButton";
import IngredientContainer from "./IngredientsContainer";

export default function IngredientsForm({ type }) {
  const { data, handleChange, loading, onSubmit, setFormType } =
    IngredientContainer();

  useEffect(() => {
    setFormType(type);
  }, [type, setFormType]);

  return (
    <div>
      <CustomForm style={type === "VIEW" ? { pointerEvents: "none" } : {}}>
        <CustomInput
          label="Name"
          name="name"
          required
          data={data}
          onChange={handleChange}
        />
        <CustomInputNumber
          label="Price"
          required
          data={data}
          name="price"
          onChange={handleChange}
          showButtons
          min={0}
          mode="currency"
          currency="EUR"
          buttonLayout="horizontal"
        />

        {type !== "VIEW" && (
          <PrimaryButton label="Save" loading={loading} onClick={onSubmit} />
        )}
      </CustomForm>
    </div>
  );
}
