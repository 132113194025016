import { types } from "../types/types";
const intitalState = {
  allLocations: [],
  name: "Test",
};

const locationsReducer = (state = intitalState, action) => {
  switch (action.type) {
    case types.CHANGE_LOCATIONS:
      return {
        ...state,
        allLocations: action.payload,
      };
    case types.CHANGE_LOCATION_NAME:
      return {
        ...state,
        name: action.payload,
      };
    default:
      return { ...state };
  }
};

export default locationsReducer;
