import React from "react";
import TableCard from "../../shared/TableCard/TableCard";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { InputSwitch } from "primereact/inputswitch";
import { useDispatch } from "react-redux";
import { ProductsEnableDisableAction } from "../../redux/actions/productsAction";

export default function ProductsTable({
  allProducts,
  loading,
  history,
  search,
  setSearch,
  locationId,
  onDelete,
}) {
  const dispatch = useDispatch();

  const actions = (d) => {
    return (
      <div className="flex justify-content-around">
        <i
          className="pi pi-eye cursor-pointer"
          onClick={() => history.push(`/products/view/${locationId}/${d._id}`)}
        ></i>
        <i
          className="pi pi-pencil cursor-pointer"
          onClick={() => history.push(`/products/edit/${locationId}/${d._id}`)}
        ></i>
        <i
          className="pi pi-trash cursor-pointer"
          onClick={() => onDelete(d._id)}
        ></i>
      </div>
    );
  };

  const handleSwitchChange = (id, active) => {
    dispatch(ProductsEnableDisableAction(id, locationId, active));
  };
  const switchTemplate = (rowData) => {
    return (
      <div className="flex">
        <InputSwitch
          checked={rowData?.isActive}
          onChange={(e) => handleSwitchChange(rowData?._id, e.value)}
        />
        <p style={{ marginLeft: "5px" }}>{rowData?.isActive ? "On" : "Off"}</p>
      </div>
    );
  };

  return (
    <TableCard
      title="Products List"
      onSearch={setSearch}
      searchKeyword={search}
      buttonTitle="Add Products"
      linkTo={`/products/add/${locationId}`}
      extraButtons={[
        { buttonTitle: "Sort", linkTo: `/products/reorder/${locationId}` },
      ]}
    >
      <DataTable
        paginator
        value={allProducts}
        className=""
        showGridlines
        rows={10}
        loading={loading}
        responsiveLayout="scroll"
        emptyMessage="No products found."
      >
        <Column field="name" header="Name" style={{ width: "250px" }}></Column>
        <Column
          field="price"
          header="Price"
          style={{ width: "200px" }}
        ></Column>
        <Column
          field="category.name"
          header="Category"
          style={{ width: "250px" }}
        ></Column>
        <Column
          field="status"
          header="Status"
          body={switchTemplate}
          style={{ width: "250px" }}
        ></Column>
        <Column
          body={actions}
          style={{ width: "250px" }}
          header="Action"
        ></Column>
      </DataTable>
    </TableCard>
  );
}
