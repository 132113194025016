import React from "react";
import CustomCard from "../../../shared/Card/CustomCard";
import AddOnForm from "./AddOnForm";

export default function CreateAddOn({ name }) {
  return (
    <CustomCard title={name}>
      <AddOnForm type="ADD" />
    </CustomCard>
  );
}
