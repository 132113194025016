import React from "react";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";

export default function CustomCard({
  title,
  children,
  className,
  backable = true,
  ...props
}) {
  const history = useHistory();
  return (
    <div className={`label-table ${className}`}>
      <div className="surface-800 py-3 px-3 flex justify-content-start w-full m-0  text-white">
        {backable ? (
          <i
            className="pi pi-angle-left text-4xl my-auto cursor-pointer"
            onClick={() => history.goBack()}
          />
        ) : null}
        <div className="text-2xl my-auto">{title}</div>
      </div>
      <div className="p-2  border border-1 border-300 p-fluid">{children}</div>
    </div>
  );
}
