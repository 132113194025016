import React from "react";
import Index from "./index";
import ProductsTable from "./ProductsTable";

const Products = () => {
  const { allProducts, loading, history, search, setSearch, id, onDelete } =
    Index();

  return (
    <ProductsTable
      allProducts={allProducts}
      loading={loading}
      history={history}
      search={search}
      setSearch={setSearch}
      locationId={id}
      onDelete={onDelete}
    />
  );
};

const comparisonFn = function (prevProps, nextProps) {
  return (
    prevProps.location.pathname === nextProps.location.pathname &&
    prevProps.colorMode === nextProps.colorMode
  );
};
export default React.memo(Products, comparisonFn);
