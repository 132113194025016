import api from "../../services/api";
import Constants from "../../services/constant";

export const getUsersCount = (returnData) => async (dispatch) => {
  const res = await api("get", Constants.END_POINT.GET_USERS_COUNT);
  if (res.success) {
    if (res.data) {
      if (returnData) {
        returnData(res.data);
      }
    }
  }
};
