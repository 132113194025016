import React from "react";
import Index from "./index";
import OrderDetailsTable from "./OrderDetailsTable";

const OrderDetails = ({ socket }) => {
  const {
    latestOrders,
    completedOrders,
    loading,
    history,
    id,
    formattedTime,
    getColor,
    getStyle,
  } = Index(socket);

  return (
    <OrderDetailsTable
      completedOrders={completedOrders}
      latestOrders={latestOrders}
      loading={loading}
      history={history}
      locationId={id}
      formattedTime={formattedTime}
      getColor={getColor}
      getStyle={getStyle}
    />
  );
};

const comparisonFn = function (prevProps, nextProps) {
  return (
    prevProps.location.pathname === nextProps.location.pathname &&
    prevProps.colorMode === nextProps.colorMode
  );
};
export default React.memo(OrderDetails, comparisonFn);
