import api from "../../services/api";
import { types } from "../types/types";
import Constants from "../../services/constant";
import { showToast } from "./toastAction";
import { hideLoaderAction, showLoaderAction } from "./loaderAction";

const getR2OProducts = (id) => async (dispatch) => {
  const res = await api("get", Constants.END_POINT.R2O_PRODUCTS + id);
  if (res.success) {
    if (res.data) {
      let payload = Object.values(res.data).map((item) => ({
        value: item.product_id,
        name: `${item.product_name} (EUR ${item.product_price})`,
      }));
      dispatch({
        type: types.CHANGE_R2O_PRODUCTS,
        payload,
      });
    }
  }
};

const getProducts = (setLoading, id) => async (dispatch) => {
  if (setLoading) {
    setLoading(true);
  }
  const res = await api(
    "get",
    Constants.END_POINT.PRODUCTS + `?location=${id}`
  );
  if (res.success) {
    if (res.data) {
      dispatch({
        type: types.CHANGE_PRODUCTS,
        payload: res.data,
      });
    }
  }
  if (setLoading) {
    setLoading(false);
  }
};
const ProductsEnableDisableAction =
  (id, locationId, active) => async (dispatch) => {
    const payload = { isActive: active };
    const res = await api("put", Constants.END_POINT.PRODUCTS + id, payload);
    if (res.success) {
      dispatch(getProducts(null, locationId));
      dispatch(showToast({ severity: "success", summary: res.message }));
    } else {
      dispatch(showToast({ severity: "error", summary: res.message }));
    }
  };
const addProducts =
  (data, setLoading, history, locationId) => async (dispatch) => {
    setLoading(true);

    const payload = {
      ...data,
      location: locationId,
    };

    const res = await api("post", Constants.END_POINT.PRODUCTS, payload);
    if (res.success) {
      getProducts(null, locationId);
      history.goBack();
      dispatch(showToast({ severity: "success", summary: res.message }));
    }
    setLoading(false);
  };

const editProducts =
  (id, data, setLoading, history, locationId) => async (dispatch) => {
    setLoading(true);

    const payload = {
      ...data,
      location: locationId,
    };

    const res = await api("put", Constants.END_POINT.PRODUCTS + id, payload);
    if (res.success) {
      getProducts(null, locationId);
      history.goBack();
      dispatch(showToast({ severity: "success", summary: res.message }));
    }
    setLoading(false);
  };
const getProduct = (id, returnData) => async (dispatch) => {
  dispatch(showLoaderAction());
  const res = await api("get", Constants.END_POINT.PRODUCTS + id);
  if (res.success) {
    dispatch(hideLoaderAction());
    if (res.data) {
      if (returnData) {
        returnData(res.data);
      }
    }
  }
  dispatch(hideLoaderAction());
};
const deleteProductAction = (id, locationId) => async (dispatch) => {
  const res = await api("delete", Constants.END_POINT.PRODUCTS + id);
  if (res.success) {
    dispatch(getProducts(null, locationId));
    dispatch(showToast({ severity: "success", summary: res.message }));
  } else {
    dispatch(showToast({ severity: "error", summary: res.message }));
  }
};

const reorderProducts = (data, setLoading, next) => async (dispatch) => {
  let payload = data.map((item) => item._id);
  setLoading(true);
  const res = await api(
    "post",
    Constants.END_POINT.PRODUCTS + "reorder",
    payload
  );
  setLoading(false);
  if (res.success) {
    if (next) {
      next();
    }
  }
};
export {
  getProducts,
  ProductsEnableDisableAction,
  addProducts,
  getProduct,
  editProducts,
  deleteProductAction,
  reorderProducts,
  getR2OProducts,
};
