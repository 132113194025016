import { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useHistory, useParams } from "react-router-dom";
import { confirmDelete } from "../../utils/commonFunctions";
import {
  deleteProductAction,
  getProducts,
  reorderProducts,
} from "../../redux/actions/productsAction";

export default function Index() {
  const history = useHistory();
  const dispatch = useDispatch();
  const { id } = useParams();
  useEffect(() => {
    dispatch(getProducts(setLoading, id));
  }, [dispatch, id]);

  let { allProducts } = useSelector((state) => state.products);

  const [loading, setLoading] = useState(false);

  const [search, setSearch] = useState("");
  const getFilteredData = (data, search) => {
    if (search.length) {
      search = search.toLowerCase();
      data = data.filter((item) => item?.name?.toLowerCase().includes(search));
    }
    return data;
  };
  const onDelete = (productId, position) => {
    confirmDelete(
      () => {
        dispatch(deleteProductAction(productId, id));
      },
      "Do you want to delete this product?",
      position
    );
  };

  const [reorderd, setReorderd] = useState([]);

  useEffect(() => {
    setReorderd(allProducts);
  }, [allProducts]);

  const handleChnage = (e) => {
    setReorderd(e.value);
  };

  const onSave = () => {
    dispatch(
      reorderProducts(reorderd, setLoading, () => {
        history.push("/products/" + id);
      })
    );
  };

  allProducts = getFilteredData(allProducts, search);
  return {
    allProducts,
    loading,
    history,
    search,
    setSearch,
    id,
    onDelete,
    handleChnage,
    reorderd,
    onSave,
  };
}
