import React from "react";
import CustomerTable from "./CustomerTable";
import Index from "./index";

const InActiveCustomers = () => {
  const { inActiveCustomers, loading, history, search, setSearch } = Index();
  return (
    <CustomerTable
      heading="InActive Customers"
      allCustomers={inActiveCustomers}
      loading={loading}
      history={history}
      search={search}
      setSearch={setSearch}
    />
  );
};

const comparisonFn = function (prevProps, nextProps) {
  return (
    prevProps.location.pathname === nextProps.location.pathname &&
    prevProps.colorMode === nextProps.colorMode
  );
};
export default React.memo(InActiveCustomers, comparisonFn);
