import React from "react";
import TableCard from "../../shared/TableCard/TableCard";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { InputSwitch } from "primereact/inputswitch";
import { useDispatch } from "react-redux";
import { getImageURL } from "../../utils/imageUrl";
import { LocationMenuEnableDisableAction } from "../../redux/actions/locationMenuAction";

export default function MenuTable({
  allLocationsMenu,
  loading,
  history,
  search,
  setSearch,
  locationId,
  onDelete,
}) {
  const dispatch = useDispatch();

  const actions = (d) => {
    return (
      <div className="flex justify-content-around">
        <i
          className="pi pi-eye cursor-pointer"
          onClick={() => history.push(`/menu/view/${d._id}`)}
        ></i>
        <i
          className="pi pi-pencil cursor-pointer"
          onClick={() => history.push(`/menu/edit/${d._id}`)}
        ></i>
        <i
          className="pi pi-trash cursor-pointer"
          onClick={() => onDelete(d._id)}
        ></i>
      </div>
    );
  };

  const handleSwitchChange = (id, active) => {
    dispatch(LocationMenuEnableDisableAction(id, locationId, active));
  };
  const switchTemplate = (rowData) => {
    return (
      <div className="flex">
        <InputSwitch
          checked={rowData?.isActive}
          onChange={(e) => handleSwitchChange(rowData?._id, e.value)}
        />
        <p style={{ marginLeft: "5px" }}>{rowData?.isActive ? "On" : "Off"}</p>
      </div>
    );
  };
  const image = (r) => {
    const image = r.image;
    return (
      <React.Fragment>
        <img
          alt={image}
          src={image && getImageURL(image)}
          onError={(e) => (e.target.src = "")}
          width={25}
          height={25}
          style={{ verticalAlign: "middle" }}
        />
      </React.Fragment>
    );
  };

  const applicablePrepTime = (r) => {
    return (
      <>
        {r.applicablePreparationTime ? (
          <i className="pi pi-check"></i>
        ) : (
          <i className="pi pi-times"></i>
        )}
      </>
    );
  };

  return (
    <TableCard
      title="Menu List"
      onSearch={setSearch}
      searchKeyword={search}
      buttonTitle="Add Menu"
      linkTo={`/menu/add/${locationId}`}
      extraButtons={[
        { buttonTitle: "Sort", linkTo: `/menu/reorder/${locationId}` },
      ]}
    >
      <DataTable
        paginator
        value={allLocationsMenu}
        className=""
        showGridlines
        rows={10}
        loading={loading}
        responsiveLayout="scroll"
        emptyMessage="No Menu found."
      >
        <Column field="name" header="Name" style={{ width: "250px" }}></Column>
        <Column body={image} header="Image" style={{ width: "200px" }}></Column>
        <Column
          field="applicablePreparationTime"
          body={applicablePrepTime}
          header="Applicable Prepration Time"
          style={{ width: "250px" }}
        ></Column>
        <Column
          field="status"
          header="Status"
          body={switchTemplate}
          style={{ width: "250px" }}
        ></Column>
        <Column
          body={actions}
          style={{ width: "250px" }}
          header="Action"
        ></Column>
      </DataTable>
    </TableCard>
  );
}
