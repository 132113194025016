import React from "react";
import CustomCard from "../../../shared/Card/CustomCard";
import MenuForm from "./MenuForm";

export default function ViewMenu({ name }) {
  return (
    <CustomCard title={name}>
      <MenuForm type="VIEW" />
    </CustomCard>
  );
}
