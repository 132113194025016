import { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import { useHistory, useParams } from "react-router-dom";
// import { getCustomer, addCustomer, editCustomer } from "../../../../redux/actions/customerActions";
import { allValidations } from "../../../utils/formValidations";
import { showFormErrors } from "../../../utils/commonFunctions";
import {
  addEmployee,
  editEmployee,
  getEmployee,
} from "../../../redux/actions/employeeAction";

export default function EmployeeContainer() {
  const dispatch = useDispatch();
  const history = useHistory();
  const { id } = useParams();
  const role = [
    { name: "Manager", value: "MANAGER" },
    { name: "Admin", value: "ADMIN" },
  ];

  useEffect(() => {
    if (id) {
      dispatch(
        getEmployee(id, (data) => {
          setData({
            name: data?.name,
            email: data?.email,
            role: data?.role,
            countryCode: data?.countryCode,
            mobile: data?.mobile,
            color: data?.color,
          });
        })
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id, dispatch]);

  const [formType, setFormType] = useState("ADD");
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState({
    name: "",
    email: "",
    password: "",
    confirmPassword: "",
    role: "MANAGER",
    countryCode: "",
    mobile: "",
    color: "ffffff",
  });

  const handleChange = ({ name, value }) => {
    console.log(name, value);
    if (formType !== "VIEW") {
      const formErrors = allValidations(name, value, data);
      setData((prev) => ({ ...prev, [name]: value, formErrors }));
    }
  };
  const PhoneInputChange = (value, data) => {
    handleChange({ name: "countryCode", value: "+" + data.dialCode });
    handleChange({ name: "mobile", value: value.slice(data.dialCode.length) });
  };

  const onSubmit = () => {
    if (showFormErrors(data, setData)) {
      if (formType === "ADD") {
        dispatch(addEmployee(data, setLoading, history));
      } else if (formType === "EDIT") {
        dispatch(editEmployee(id, data, setLoading, history));
      }
    }
  };

  return {
    data,
    handleChange,
    loading,
    onSubmit,
    setFormType,
    role,
    PhoneInputChange,
  };
}
