import { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import { useHistory, useParams, useLocation } from "react-router-dom";
import { allValidations } from "../../../utils/formValidations";
import { setTime, showFormErrors } from "../../../utils/commonFunctions";
import {
  addBranchLocation,
  editBranchLocation,
  getConnectURL,
  getLocation,
  connectURL,
} from "../../../redux/actions/locationAction";

export default function LocationContainer() {
  const dispatch = useDispatch();
  const history = useHistory();
  const { id } = useParams();

  const { search } = useLocation();
  const searchParams = new URLSearchParams(search);
  const accountToken = searchParams.get("accountToken");

  useEffect(() => {
    if (accountToken) {
      dispatch(
        connectURL(id, accountToken, () => {
          getLocationDetails(id);
        })
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [accountToken]);

  useEffect(() => {
    if (id) {
      getLocationDetails(id);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id, dispatch]);

  const getLocationDetails = (id) => {
    dispatch(
      getLocation(id, (data) => {
        setLocationData({
          name: data.name,
          address: data.address,
          tagLine: data.tagLine,
          orderReadyWithIn: data.orderReadyWithIn,
          orderCancelBefore: data.orderCancelBefore,
          accountAccessToken: data.accountAccessToken,
        });
        let days = data.openDays.map((item) => ({
          ...item,
          openTime: setTime(item.openTime),
          closeTime: setTime(item.closeTime),

          rushHourStartTime: item.rushHourStartTime
            ? setTime(item.rushHourStartTime)
            : "",
          rushHourEndTime: item.rushHourEndTime
            ? setTime(item.rushHourEndTime)
            : "",
        }));
        setDays(days);
        setReadyToOrder(data.readyToOrderAccount);
      })
    );
  };

  const [formType, setFormType] = useState("ADD");
  const [loading, setLoading] = useState(false);
  const [locationData, setLocationData] = useState({
    name: "",
    address: "",
    tagLine: "",
    orderReadyWithIn: 5,
    orderCancelBefore: 15,
  });

  const [readyToOrder, setReadyToOrder] = useState({
    accountAccessToken: "",
  });

  const handleTokenChange = ({ name, value }) => {
    if (formType !== "VIEW") {
      const formErrors = allValidations(name, value, readyToOrder);
      setReadyToOrder((prev) => ({ ...prev, [name]: value, formErrors }));
    }
  };

  const [weekDay] = useState([
    "Sunday",
    "Monday",
    "Tuesday",
    "Wednesday",
    "Thursday",
    "Friday",
    "Saturday",
  ]);

  const [days, setDays] = useState([
    {
      weekDay: "0",
      isClosed: true,
      openTime: setTime("11:00"),
      closeTime: setTime("19:00"),
      rushHourStartTime: setTime("12:00"),
      rushHourEndTime: setTime("13:00"),
    },
    {
      weekDay: "1",
      isClosed: false,
      openTime: setTime("11:00"),
      closeTime: setTime("19:00"),
      rushHourStartTime: setTime("12:00"),
      rushHourEndTime: setTime("13:00"),
    },
    {
      weekDay: "2",
      isClosed: false,
      openTime: setTime("11:00"),
      closeTime: setTime("19:00"),
      rushHourStartTime: setTime("12:00"),
      rushHourEndTime: setTime("13:00"),
    },
    {
      weekDay: "3",
      isClosed: false,
      openTime: setTime("11:00"),
      closeTime: setTime("19:00"),
      rushHourStartTime: setTime("12:00"),
      rushHourEndTime: setTime("13:00"),
    },
    {
      weekDay: "4",
      isClosed: false,
      openTime: setTime("11:00"),
      closeTime: setTime("19:00"),
      rushHourStartTime: setTime("12:00"),
      rushHourEndTime: setTime("13:00"),
    },
    {
      weekDay: "5",
      isClosed: false,
      openTime: setTime("11:00"),
      closeTime: setTime("19:00"),
      rushHourStartTime: setTime("12:00"),
      rushHourEndTime: setTime("13:00"),
    },
    {
      weekDay: "6",
      isClosed: false,
      openTime: setTime("11:00"),
      closeTime: setTime("19:00"),
      rushHourStartTime: setTime("12:00"),
      rushHourEndTime: setTime("13:00"),
    },
  ]);

  const handleDay = (e, i) => {
    let _days = [...days];
    _days[i].isClosed = !e.value;
    setDays(_days);
  };

  const handleChangeOpeningTime = (e, i) => {
    const { name, value } = e.target;
    let _days = [...days];
    _days[i][name] = value;
    setDays(_days);
  };

  const handleChange = ({ name, value }) => {
    if (formType !== "VIEW") {
      const formErrors = allValidations(name, value, locationData);
      setLocationData((prev) => ({ ...prev, [name]: value, formErrors }));
    }
  };

  const onSubmit = () => {
    if (showFormErrors(locationData, setLocationData)) {
      if (formType === "ADD") {
        dispatch(addBranchLocation(locationData, days, setLoading, history));
      } else if (formType === "EDIT") {
        dispatch(
          editBranchLocation(id, locationData, days, setLoading, history)
        );
      }
    }
  };

  const [loading1, setLoading1] = useState(false);

  const autoConnectReadyToOrder = () => {
    dispatch(
      getConnectURL("/#" + history.location.pathname, setLoading1, (data) => {
        window.open(data.grantAccessUri);
      })
    );
  };

  return {
    locationData,
    days,
    handleDay,
    weekDay,
    handleChangeOpeningTime,
    handleChange,
    loading,
    onSubmit,
    setFormType,
    handleTokenChange,
    readyToOrder,
    autoConnectReadyToOrder,
    loading1,
  };
}
