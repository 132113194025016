import api from "../../services/api";
import { types } from "../types/types";
import Constants from "../../services/constant";
import { showToast } from "./toastAction";

const getOrdersMenu = (setLoading, id) => async (dispatch) => {
  if (setLoading) {
    setLoading(true);
  }
  const res = await api("get", Constants.END_POINT.ORDERS + `?location=${id}`);
  if (res.success) {
    if (res.data) {
      dispatch({
        type: types.CHANGE_ORDERS_MENU,
        payload: res.data,
      });
    }
  }
  if (setLoading) {
    setLoading(false);
  }
};
const ChangeOrderStatusAction =
  (id, locationId, status) => async (dispatch) => {
    const payload = { status: status };
    const res = await api(
      "put",
      Constants.END_POINT.CHANGE_ORDER_STATUS + id,
      payload
    );
    if (res.success) {
      dispatch(getOrdersMenu(null, locationId));
      // dispatch(showToast({ severity: "success", summary: res.message }));
    } else {
      dispatch(showToast({ severity: "error", summary: res.message }));
    }
  };
export { getOrdersMenu, ChangeOrderStatusAction };
