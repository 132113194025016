import React from "react";
import TableCard from "../../shared/TableCard/TableCard";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { InputSwitch } from "primereact/inputswitch";
import { useDispatch } from "react-redux";
import { CustomerEnableDisableAction } from "../../redux/actions/customerAction";
import moment from "moment";

export default function CustomerTable({
  heading,
  allCustomers,
  loading,
  history,
  search,
  setSearch,
}) {
  const dispatch = useDispatch();
  const actions = (d) => {
    return (
      <i
        className="pi pi-eye cursor-pointer"
        onClick={() => history.push(`customers/view/${d._id}`)}
      ></i>
    );
  };

  const handleSwitchChange = (id) => {
    dispatch(CustomerEnableDisableAction(id));
  };

  const switchTemplate = (rowData) => {
    return (
      <div className="flex">
        <InputSwitch
          checked={rowData.isActive}
          onChange={() => handleSwitchChange(rowData?._id)}
        />
        <p style={{ marginLeft: "5px" }}>{rowData?.isActive ? "On" : "Off"}</p>
      </div>
    );
  };

  return (
    <TableCard title={heading} onSearch={setSearch} searchKeyword={search}>
      <DataTable
        paginator
        value={allCustomers}
        className=""
        showGridlines
        rows={10}
        loading={loading}
        // responsiveLayout="scroll"
        emptyMessage="No customer found."
      >
        <Column
          field="name"
          body={(rowData) => (rowData.name ? rowData.name : "-")}
          header="Name"
          style={{ width: "250px" }}
        ></Column>
        <Column
          field="email"
          header="Email"
          style={{ width: "250px" }}
        ></Column>
        <Column
          field="mobile"
          header="Mobile"
          style={{ width: "250px" }}
        ></Column>
        <Column
          field="createdon"
          body={(rowData) => moment(rowData.time).format("DD-MM-YYYY")}
          header="Created On"
          style={{ width: "250px" }}
        ></Column>
        <Column
          field="status"
          header="Status"
          body={switchTemplate}
          style={{ width: "250px" }}
        ></Column>
        <Column
          body={actions}
          style={{ width: "250px" }}
          header="Action"
        ></Column>
      </DataTable>
    </TableCard>
  );
}
